var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("el-card", { staticClass: "boxL2" }, [
        _c("div", [
          _c("div", { staticClass: "title" }, [_vm._v("今日销售")]),
          _c("div", { staticClass: "todayBox x-b" }, [
            _c("div", { staticClass: "todaySale x-c" }, [
              _c("div", { staticClass: "circle y-c" }, [
                _c("div", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.dailyData.saleOutboundMoney)),
                ]),
                _c("div", { staticClass: "font" }, [_vm._v("今日销售总额")]),
              ]),
            ]),
            _c("div", { staticClass: "todaySaleL" }, [
              _c("div", { staticClass: "topL x-f" }, [
                _c("div", { staticClass: "contentSale y-f-j" }, [
                  _c("div", { staticClass: "top" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.dailyData.saleOrderMoney) +
                        "元/" +
                        _vm._s(_vm.dailyData.saleOrderCount) +
                        "单 "
                    ),
                  ]),
                  _c("div", { staticClass: "bottom" }, [_vm._v("销售订单")]),
                ]),
                _c("div", { staticClass: "contentSale y-f-j" }, [
                  _c("div", { staticClass: "top" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.dailyData.saleOutboundMoney) +
                        "元/" +
                        _vm._s(_vm.dailyData.saleOutboundCount) +
                        "单 "
                    ),
                  ]),
                  _c("div", { staticClass: "bottom" }, [_vm._v("销售出库")]),
                ]),
              ]),
              _c("div", { staticClass: "topL x-f" }, [
                _c("div", { staticClass: "contentSale y-f-j" }, [
                  _c("div", { staticClass: "top" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.dailyData.saleReturnMoney) +
                        "元/" +
                        _vm._s(_vm.dailyData.saleReturnCount) +
                        "单 "
                    ),
                  ]),
                  _c("div", { staticClass: "bottom" }, [_vm._v("销售退货")]),
                ]),
                _c("div", { staticClass: "contentSale y-f-j" }, [
                  _c("div", { staticClass: "top" }, [
                    _vm._v(_vm._s(_vm.dailyData.saleGrossProfit) + "元"),
                  ]),
                  _c("div", { staticClass: "bottom" }, [_vm._v("毛利")]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        [
          _c("el-card", { staticClass: "shopping marT10" }, [
            _c("div", [
              _c("div", { staticClass: "title" }, [_vm._v("采购情况")]),
              _c("div", [
                _c("div", { staticClass: "x-bc marT30 marB30" }, [
                  _c("div", { staticClass: "contentShopping y-f-j" }, [
                    _c("div", { staticClass: "top" }, [
                      _vm._v(_vm._s(_vm.dailyData.purchaseAmountTotal) + "元"),
                    ]),
                    _c("div", { staticClass: "bottom" }, [
                      _vm._v("今日采购总金额"),
                    ]),
                  ]),
                  _c("div", { staticClass: "contentShopping y-f-j" }, [
                    _c("div", { staticClass: "top" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dailyData.purchaseOrderMoney) +
                          "元/" +
                          _vm._s(_vm.dailyData.purchaseOrderCount) +
                          "单 "
                      ),
                    ]),
                    _c("div", { staticClass: "bottom" }, [_vm._v("采购订单")]),
                  ]),
                  _c("div", { staticClass: "contentShopping y-f-j" }, [
                    _c("div", { staticClass: "top" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dailyData.purchaseReceiptMoney) +
                          "元/" +
                          _vm._s(_vm.dailyData.purchaseReceiptCount) +
                          "单 "
                      ),
                    ]),
                    _c("div", { staticClass: "bottom" }, [_vm._v("采购入库")]),
                  ]),
                  _c("div", { staticClass: "contentShopping y-f-j" }, [
                    _c("div", { staticClass: "top" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dailyData.purchaseReturnMoney) +
                          "元/" +
                          _vm._s(_vm.dailyData.purchaseReturnCount) +
                          "单 "
                      ),
                    ]),
                    _c("div", { staticClass: "bottom" }, [_vm._v("采购退货")]),
                  ]),
                ]),
              ]),
              _c("div", {
                staticStyle: { width: "100%", height: "420px" },
                attrs: { id: "chart-containerAdd" },
              }),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }