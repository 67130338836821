var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "el-card",
        [
          _c("div", {
            staticStyle: { width: "100%", height: "400px" },
            attrs: { id: "chart-container" },
          }),
          _c("el-slider", {
            staticClass: "slider",
            attrs: {
              range: "",
              min: _vm.minTime,
              max: _vm.maxTime,
              "format-tooltip": _vm.formatTooltip,
            },
            on: { change: _vm.sliderChange },
            model: {
              value: _vm.selectedDays,
              callback: function ($$v) {
                _vm.selectedDays = $$v
              },
              expression: "selectedDays",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-f" },
        [
          _c(
            "el-card",
            {
              staticClass: "marT10 padd15 marR10",
              staticStyle: { width: "63%" },
            },
            [
              _c("div", { staticClass: "x-bc" }, [
                _c("div", { staticClass: "distributionTitleText" }, [
                  _c("span", [_vm._v("销售分布")]),
                  _c("span", { staticClass: "marR5 marL5" }, [
                    _vm._v(
                      "（" + _vm._s("按" + _vm.distributionTopName) + "）"
                    ),
                  ]),
                  _c("span", [_vm._v("TOP 20")]),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        on: { change: _vm.distributionChange },
                        model: {
                          value: _vm.distributionParams.filterTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.distributionParams, "filterTime", $$v)
                          },
                          expression: "distributionParams.filterTime",
                        },
                      },
                      [
                        _c(
                          "el-radio-button",
                          { attrs: { label: "thisWeek" } },
                          [_vm._v("本周")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "thisMonth" } },
                          [_vm._v("本月")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "thisYear" } },
                          [_vm._v("本年")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticClass: "distributionSelect marL10 marR20",
                        attrs: { placeholder: "全部", filterable: "" },
                        on: { change: _vm.distributionChange },
                        model: {
                          value: _vm.distributionParams.searchType,
                          callback: function ($$v) {
                            _vm.$set(_vm.distributionParams, "searchType", $$v)
                          },
                          expression: "distributionParams.searchType",
                        },
                      },
                      _vm._l(_vm.searchTypeList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.name, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", {
                ref: "chartDistribution",
                staticStyle: { width: "100%", height: "475px" },
              }),
            ]
          ),
          _c(
            "div",
            { staticStyle: { flex: "1" } },
            [
              _c("el-card", { staticClass: "marT10 padd15 topRace" }, [
                _c("div", { staticClass: "x-bc" }, [
                  _c("div", { staticClass: "distributionTitleText" }, [
                    _c("span", [_vm._v("销售环比增长TOP 10")]),
                  ]),
                  _c("div"),
                ]),
                _c("div", {
                  ref: "chartTop",
                  staticStyle: { width: "100%", height: "180px" },
                }),
                _c("div", { staticClass: "showNum" }, [
                  _c("span", { staticClass: "nolmalFont" }, [
                    _vm._v("总增长:" + _vm._s(_vm.increaseMoney || 0)),
                  ]),
                  _c("span", { staticClass: "grayFont" }, [
                    _vm._v("(共" + _vm._s(_vm.topData.length || 0) + "个商品)"),
                  ]),
                ]),
              ]),
              _c("el-card", { staticClass: "marT10 padd15 topRace" }, [
                _c("div", { staticClass: "x-bc" }, [
                  _c("div", { staticClass: "distributionTitleText" }, [
                    _c("span", [_vm._v("销售环比下滑TOP 10")]),
                  ]),
                  _c("div"),
                ]),
                _c("div", {
                  ref: "chartBottom",
                  staticStyle: { width: "100%", height: "180px" },
                }),
                _c("div", { staticClass: "showNum" }, [
                  _c("span", { staticClass: "nolmalFont" }, [
                    _vm._v("总下降:" + _vm._s(_vm.decreaseMoney || 0)),
                  ]),
                  _c("span", { staticClass: "grayFont" }, [
                    _vm._v(
                      "(共" + _vm._s(_vm.downData.length || 0) + "个商品)"
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "marT10 padd15" },
        [
          _c("div", { staticClass: "x-bc" }, [
            _c("div", { staticClass: "distributionTitleText" }, [
              _c("span", [_vm._v("销售排行")]),
              _c("span", { staticClass: "marR5 marL5" }, [
                _vm._v("（" + _vm._s("按" + _vm.rankeName) + "）"),
              ]),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "mini" },
                    on: { change: _vm.rankeNameChange },
                    model: {
                      value: _vm.rankeNameParams.filterTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.rankeNameParams, "filterTime", $$v)
                      },
                      expression: "rankeNameParams.filterTime",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "thisWeek" } }, [
                      _vm._v("本周"),
                    ]),
                    _c("el-radio-button", { attrs: { label: "thisMonth" } }, [
                      _vm._v("本月"),
                    ]),
                    _c("el-radio-button", { attrs: { label: "thisYear" } }, [
                      _vm._v("本年"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticClass: "distributionSelect marL10 marR20",
                    attrs: { placeholder: "全部", filterable: "" },
                    on: { change: _vm.rankeNameChange },
                    model: {
                      value: _vm.rankeNameParams.searchType,
                      callback: function ($$v) {
                        _vm.$set(_vm.rankeNameParams, "searchType", $$v)
                      },
                      expression: "rankeNameParams.searchType",
                    },
                  },
                  _vm._l(_vm.searchTypeList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.name, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingTable,
                  expression: "loadingTable",
                },
              ],
              ref: "multipleTable",
              staticClass: "marT10",
              attrs: {
                "tooltip-effect": "dark",
                data: _vm.rankeTableData,
                border: "",
                "max-height": "500",
              },
              on: { "sort-change": _vm.handleSortChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  align: "center",
                  prop: "userId",
                  width: "80",
                  type: "index",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.rankeName,
                  align: "center",
                  prop: "typeName",
                  width: "600",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "销售数量",
                  align: "center",
                  sortable: "custom",
                  prop: "billQty",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "销售金额",
                  align: "center",
                  sortable: "custom",
                  prop: "billMoney",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }