<template>
  <div class="wrap">
    <!-- <div class="x-bc">
      <el-card class="boxL">
        <div>
          <div class="title">今日零售</div>
          <div>
            <div class="x-bc marT30 marB30">
              <div class="content y-f-j">
                <div class="top">9999元/单</div>
                <div class="bottom">销售总金额</div>
              </div>
              <div class="content y-f-j">
                <div class="top">9999元/单</div>
                <div class="bottom">退货总金额</div>
              </div>
              <div class="content y-f-j">
                <div class="top">9999元/单</div>
                <div class="bottom">预定总金额</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="boxR">
        <div>
          <div class="title">商城销售情况</div>
        </div>
        <div>
          <div class="x-b marT30 marB30">
            <div class="content y-f-j">
              <div class="top">9999元/单</div>
              <div class="bottom">商城订单</div>
            </div>

            <div class="content y-f-j">
              <div class="top">9999元/单</div>
              <div class="bottom">商城退款</div>
            </div>
          </div>
        </div>
      </el-card>
    </div> -->

    <el-card class="boxL2">
      <div>
        <div class="title">今日销售</div>

        <div class="todayBox x-b">
          <div class="todaySale x-c">
            <div class="circle y-c">
              <div class="num">{{ dailyData.saleOutboundMoney }}</div>
              <div class="font">今日销售总额</div>
            </div>
          </div>
          <div class="todaySaleL">
            <div class="topL x-f">
              <div class="contentSale y-f-j">
                <div class="top">
                  {{ dailyData.saleOrderMoney }}元/{{
                    dailyData.saleOrderCount
                  }}单
                </div>
                <div class="bottom">销售订单</div>
              </div>
              <div class="contentSale y-f-j">
                <div class="top">
                  {{ dailyData.saleOutboundMoney }}元/{{
                    dailyData.saleOutboundCount
                  }}单
                </div>
                <div class="bottom">销售出库</div>
              </div>
            </div>
            <div class="topL x-f">
              <div class="contentSale y-f-j">
                <div class="top">
                  {{ dailyData.saleReturnMoney }}元/{{
                    dailyData.saleReturnCount
                  }}单
                </div>
                <div class="bottom">销售退货</div>
              </div>
              <div class="contentSale y-f-j">
                <div class="top">{{ dailyData.saleGrossProfit }}元</div>
                <div class="bottom">毛利</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <!-- <el-card class="boxR2">
        <div>
          <div class="title">商城销售情况</div>
        </div>
        <div class="shop x-f1">
          <div class="y-b">
            <div class="contentSale y-f-j">
              <div class="top">9999元/单</div>
              <div class="bottom">销售总金额</div>
            </div>
            <div class="contentSale y-f-j">
              <div class="top">9999元/单</div>
              <div class="bottom">销售总金额</div>
            </div>
          </div>
          <div class="y-b">
            <div class="contentSale y-f-j">
              <div class="top">9999元/单</div>
              <div class="bottom">销售总金额</div>
            </div>
            <div class="contentSale y-f-j">
              <div class="top">9999元/单</div>
              <div class="bottom">销售总金额</div>
            </div>
          </div>
        </div>
      </el-card> -->

    <div>
      <el-card class="shopping marT10">
        <div>
          <div class="title">采购情况</div>
          <div>
            <div class="x-bc marT30 marB30">
              <div class="contentShopping y-f-j">
                <div class="top">{{ dailyData.purchaseAmountTotal }}元</div>
                <div class="bottom">今日采购总金额</div>
              </div>
              <div class="contentShopping y-f-j">
                <div class="top">
                  {{ dailyData.purchaseOrderMoney }}元/{{
                    dailyData.purchaseOrderCount
                  }}单
                </div>
                <div class="bottom">采购订单</div>
              </div>
              <div class="contentShopping y-f-j">
                <div class="top">
                  {{ dailyData.purchaseReceiptMoney }}元/{{
                    dailyData.purchaseReceiptCount
                  }}单
                </div>
                <div class="bottom">采购入库</div>
              </div>
              <div class="contentShopping y-f-j">
                <div class="top">
                  {{ dailyData.purchaseReturnMoney }}元/{{
                    dailyData.purchaseReturnCount
                  }}单
                </div>
                <div class="bottom">采购退货</div>
              </div>
            </div>
          </div>
          <div id="chart-containerAdd" style="width: 100%; height: 420px"></div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { businessDailyAPI } from '@/api/sale/analyse/businessDaily'
export default {
  name: 'businessDaily',
  data () {
    return {
      dailyData: '',
      purchases: [],
      xAxisData: [], // 消费趋势初始化 x 轴数据
      seriesData: [], // 初始化系列数据
      myChartAdd: null
    }
  },
  async mounted () {
    await this.getList()
    this.initChartAdd() //会员增长
  },
  methods: {
    initChartAdd () {
      //会员增长
      // if (this.myChartAdd) {
      //   this.myChartAdd.dispose(); // 销毁之前的实例
      // }
      const chartContainerAdd = document.getElementById('chart-containerAdd')
      this.myChartAdd = this.$echarts.init(chartContainerAdd)
      //会员增长
      this.myChartAdd.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              type: 'dash'
            }
          }
        },
        formatter: function (params) {
          var xAxisValue = params[0].axisValue // 获取 x 轴的信息
          var value = params[0].value
          return xAxisValue + ': ' + value + '元' // 在 tooltip 中同时显示 x 轴和数值带 "元" 字
        },

        grid: {
          left: '1%',
          right: '1%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.xAxisData,
            // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#333'
              }
            },
            boundaryGap: true, // 在柱状图中通常需要设置为 true
            axisLabel: {
              fontSize: 14 // 设置字体大小
            }
          }
        ],
        yAxis: [
          {
            type: 'value',

            axisLine: {
              show: false,
              lineStyle: {
                color: '#999999'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              fontSize: 14 // 设置字体大小
            }
          }
        ],
        series: [
          {
            type: 'bar', // 将折线图改为柱状图
            barWidth: '5%',
            data: this.seriesData, // 将柱状图的数据设置为 YData
            // data: [10, 52, 200, 334, 390, 330, 220],
            itemStyle: {
              color: '#40B5FF'
            }
          }
        ]
      })
      this.xAxisData = [] //清空数据
      this.seriesData = []
      // 监听窗口大小变化，实现图表的自适应
      window.addEventListener('resize', () => {
        this.myChartAdd.resize()
      })
    },
    async getList () {
      this.loadingTable = true
      const res = await businessDailyAPI()
      this.dailyData = res.data
      // console.log("dailyData", this.dailyData);
      this.dailyData.purchases.forEach(item => {
        //消费趋势
        this.xAxisData.push(item.goodsCategory)
        this.seriesData.push(item.money)
      })
      // console.log("123131", this.xAxisData);
      // console.log("122163213131", this.seriesData);
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px;
  background-color: #eaeaea;
}
.title {
  font-weight: 700;
}
.boxL {
  width: 60%;
  margin-right: 5px;
  height: 230px;
}
.shopping {
  width: 100%;

  height: 589px;
}
.boxL2 {
  width: 100%;

  height: 350px;
}
.boxR {
  width: 40%;
  height: 230px;
}
.boxR2 {
  width: 45%;
  height: 350px;
}
.content {
  //  display: flex;
  // align-items: center; /* 垂直居中对齐 */
  // justify-content: center; /* 水平居中对齐 */
  width: 310px;
  height: 86px;
  background-color: #f3f9ff;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 20px;
}
.contentSale {
  display: flex;
  width: 100%;
  height: 120px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #f7f8f9;
  margin-bottom: 10px;
}
.contentShopping {
  // display: flex;
  // align-items: center; /* 垂直居中对齐 */
  // justify-content: center; /* 水平居中对齐 */
  width: 310px;
  height: 86px;
  background-color: #f7f8f9;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px;
}
.top {
  font-size: 16px;
  font-weight: 400;
  font-weight: bold;
  // color: #333333;
  color: #333;
  line-height: 24px;
  margin-left: 20px;
  letter-spacing: 0.05em;
}
.bottom {
  margin-left: 20px;
  font-size: 14px;
}
.circle {
  width: 185px;
  height: 185px;
  border: 10px solid #40b5ff; /* 边框宽度控制圆环的宽度 */
  border-radius: 50%; /* 将正方形变为圆形 */
}
.todayBox {
  margin-top: 20px;
  // border: 1px solid pink;
}
.todaySale {
  //圆的背景
  width: 30%;
  height: 249px;
  background: #f7f8f9;
  // margin-top: 20px;
}
.todaySaleL {
  width: 70%;
  margin-left: 5px;
  // border: 1px solid cadetblue;
}
.saleL {
  width: 50%;
}
.saleR {
  width: 50%;
}
.num {
  font-size: 16px;
  font-weight: bold;
  // color: #333333;
  color: #333;
}
.font {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.shop {
  margin-top: 20px;
}
</style>
