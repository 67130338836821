<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { saleOrderSummaryListAPI, sumSaleOrderSummaryListAPI } from '@/api/sale/report' //销售报表

export default {
  name: "orderSummary",
  components: { TablePage },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "销售订单汇总表",
        labelWidth: "80",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "input",
            tip:"单据编号、商品编号、商品名称、类别编号、类别名称、仓库编号、仓库名称、业务员编号、业务员名称、客户编号、客户名称",
            model: "",
            filter: "query",
          },
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCustomer",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSimpleQuery",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "partnerFun",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listPurCreateEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listPurAuditEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        getListApi: saleOrderSummaryListAPI,
        getSummaryApi: sumSaleOrderSummaryListAPI,
        tabsColumns: [
          {
            title: "客户汇总查询",
            exportOption: {
              exportApi: 'exportSaleOrderSummary',
              exportName: '客户汇总查询'
            },
            defaultBody: { searchType: "partner" },
            type: "partner",
            columns: [
              {
                prop: "partnerNo",
                label: "客户编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "客户名称",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: ["unitTaxMoney", "billNotaxMoney", "unitPriceTaxMoney"],
          },
          {
            title: "客户+业务员汇总查询",
            exportOption: {
              exportApi: 'exportSaleOrderSummary',
              exportName: '客户+业务员汇总查询'
            },
            defaultBody: { searchType: "employeePartner" },
            type: "employeePartner",
            columns: [
              {
                prop: "partnerNo",
                label: "客户编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "客户名称",
                minWidth: 150,
              },
              {
                prop: "employeeNo",
                label: "业务员编码",
                minWidth: 150,
              },
              {
                prop: "employeeName",
                label: "业务员名称",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: ["unitTaxMoney", "billNotaxMoney", "unitPriceTaxMoney"],
          },
          {
            title: "商品汇总查询",
            exportOption: {
              exportApi: 'exportSaleOrderSummary',
              exportName: '商品汇总查询'
            },
            defaultBody: { searchType: "goods" },
            type: "goods",
            columns: [
              {
                prop: "categoryNo",
                label: "商品类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "商品条码",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 150,
              },
              {
                prop: "basUnitPrice",
                label: "基本单价",
                minWidth: 150,
              },
              {
                prop: "basUnitQty",
                label: "基本单位数量",
                minWidth: 150,
              },
              // {
              //   prop: "unitName",
              //   label: "单位",
              //   minWidth: 150,
              // },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: ["basUnitQty", "unitTaxMoney", "billNotaxMoney", "unitPriceTaxMoney"],
          },
          {
            title: "商品类别汇总查询",
            exportOption: {
              exportApi: 'exportSaleOrderSummary',
              exportName: '商品类别汇总查询'
            },
            defaultBody: { searchType: "goodsCategory" },
            type: "goodsCategory",
            columns: [
              {
                prop: "categoryNo",
                label: "商品类别编码",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别名称",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: ["unitTaxMoney", "billNotaxMoney", "unitPriceTaxMoney"],
          },
          {
            title: "业务员汇总查询",
            exportOption: {
              exportApi: 'exportSaleOrderSummary',
              exportName: '业务员汇总查询'
            },
            defaultBody: { searchType: "employee" },
            type: "employee",
            columns: [
              {
                prop: "employeeNo",
                label: "业务员编码",
                minWidth: 150,
              },
              {
                prop: "employeeName",
                label: "业务员名称",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: ["unitTaxMoney", "billNotaxMoney", "unitPriceTaxMoney"],
          },
          {
            title: "仓库汇总查询",
            exportOption: {
              exportApi: 'exportSaleOrderSummary',
              exportName: '仓库汇总查询'
            },
            defaultBody: { searchType: "store" },
            type: "store",
            columns: [
              {
                prop: "storeNo",
                label: "仓库编码",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库名称",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: ["unitTaxMoney", "billNotaxMoney", "unitPriceTaxMoney"],
          },
          {
            title: "部门汇总查询",
            exportOption: {
              exportApi: 'exportSaleOrderSummary',
              exportName: '部门汇总查询'
            },
            defaultBody: { searchType: "dept" },
            type: "dept",
            columns: [
              {
                prop: "deptNo",
                label: "部门编码",
                minWidth: 150,
              },
              {
                prop: "deptName",
                label: "部门名称",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: ["unitTaxMoney", "billNotaxMoney", "unitPriceTaxMoney"],
          },
        ],
      },
    };
  },
};
</script>
