<template>
  <!-- 销售出库单 -->
  <div class="outstore-detail">
    <detail :billType="billType">
      <!-- 插槽 -->
      <template #tableBillSource>
        <el-table-column
          label="单据源"
          width="160"
          align="center"
          :formatter="id2Content"
          show-overflow-tooltip
        >
        </el-table-column>
      </template>
      <template #tableSpecial>
        <el-table-column label="应出数量" align="center" prop="importUnitQty">
        </el-table-column>
      </template>
    </detail>
  </div>
</template>

<script>
import detail from '@/views/components/bill/detail-layout.vue'
export default {
  name: 'SaleOutDetail',
  components: { detail },
  data () {
    return {
      billType: '120102'
    }
  },
  methods: {
    id2Content (row, column, cellValue, index) {
      if (row.sourceBillDetailId) {
        return '销售订单'
      }
    }
  }
}
</script>
